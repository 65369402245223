import PostPreview from '../components/PostPreview';

function Posts(props) {
  return (
    <div className="space-y-10">
      <PostPreview/>
      <PostPreview/>
      <PostPreview/>
      <PostPreview/>
      <PostPreview/>
      <PostPreview/>
    </div>
  );
}

export default Posts;