import {Link} from "react-router-dom";

function PostPreview(props) {
  return (
    <div>
      <Link to="/post/next-blog-structured-mdx-content-with-contentlayer" className="block overflow-hidden rounded-2xl bg-white/[2%] p-7 shadow-surface-elevation-low transition duration-300 hover:bg-white/[3%] hover:shadow-surface-elevation-medium focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-500/70">
        <h3 className="text-xl text-rose-100/80 transition duration-300 line-clamp-2 hover:text-rose-100/90 mb-2">
          Turn Freeform MDX Content into Structured Data with Contentlayer
        </h3>
        <div className="flex flex-wrap space-x-2 text-gray-500/90">
          <div>Apr, 22</div>
          <div className="text-rose-100/30">·</div>
          <div><span className="-mx-0.5 animate-[mutation_2s_ease-in-out_1] rounded-md px-0.5 slashed-zero tabular-nums tracking-tight">1,317</span> views</div>
          <div className="text-rose-100/30">·</div>
          <div><span className="-mx-0.5 animate-[mutation_2s_ease-in-out_1] rounded-md px-0.5 slashed-zero tabular-nums tracking-tight">172</span> likes</div>
        </div>
        <p className="mt-4 text-lg text-gray-400/90 line-clamp-3">
          Deal with the chaos of freeform MDX content by transforming it into structured type-safe data and reduce the boilerplate and external tools required to effectively manage MDX content.
        </p>
      </Link>
    </div>
  );
}

export default PostPreview;