import {Link, useLocation} from "react-router-dom";

function NavButton({label, link, children, className, externalLink}) {
  let location = useLocation();
  
  let linkClass = location.pathname === link ?
    `group rounded-xl transition duration-500 sm:py-0.5 sm:pr-3 sm:hover:bg-white/[2%] focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-500/70 sm:bg-white/[3%]` :
    `group rounded-xl transition duration-500 sm:py-0.5 sm:pr-3 sm:hover:bg-white/[2%] focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-500/70`;

  let iconClass = location.pathname === link ?
    `rounded-lg bg-gradient-to-tl from-purple-500/80 to-rose-400/80 p-1 shadow-lg transition-all duration-300 ease-out group-hover:scale-[1.2] group-hover:rounded-[10px] group-hover:shadow-purple-500/40 group-active:translate-y-1 ring-[2px] ring-purple-500/30 ring-offset-1 ring-offset-black/5` :
    `rounded-lg bg-gradient-to-tl from-purple-500/80 to-rose-400/80 p-1 shadow-lg transition-all duration-300 ease-out group-hover:scale-[1.2] group-hover:rounded-[10px] group-hover:shadow-purple-500/40 group-active:translate-y-1`;
  
  const content = (
    <div className="sm:flex sm:items-center sm:space-x-2">
      <div className="mb-1.5 flex justify-center sm:mb-0 sm:block">
        <div className={iconClass}>
          {children}
        </div>
      </div>
      <div className={`transition duration-500 sm:group-hover:translate-x-[1px] ${className}`}>{label}</div>
    </div>
  );
  if (location.pathname === link) {
    console.log(link)
  }

  if (externalLink === true) {
    return <a href={link} target="_blank" rel="noreferrer" className={linkClass}>
      {content}
    </a>;
  }
  return (
    <Link to={link} className={linkClass}>
      {content}
    </Link>
  );
}

export default NavButton;