import NavButton from './NavButton';
import {Link, useLocation} from "react-router-dom";
import {useState, useEffect} from "react";

function FloatingBanner(props) {
  let location = useLocation();
  const [show, setShow] = useState(false);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      // window.scrollY > lastScrollY
      if (location.pathname !== '/') {
        setShow(true);
      } else {
        if (window.scrollY > 400) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    }
  };
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      controlNavbar();
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [controlNavbar]);
  
  return (
    <div className={`fixed top-6 z-30 w-full transition-opacity transform-gpu duration-300 ${(show) ? 'opacity-100' : 'opacity-0'}`}>
      <div className="mx-auto w-full px-[15px] sm:max-w-screen-sm">
        <div className="flex items-center justify-between rounded-2xl bg-white/[3%] px-4 py-2.5 shadow-surface-glass backdrop-blur backdrop-filter firefox:bg-gray-900 firefox:bg-opacity-90">
          <div className="flex items-center space-x-6">
            <Link to="/" title="🏠" className="rounded-full focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-500/70">
              <div className="rounded-full bg-gradient-to-tl from-purple-700/60 to-rose-400/60 shadow-lg p-[2px] group transform transition ease-out hover:scale-105 hover:from-purple-700 hover:to-rose-400 hover:shadow-rose-500/25 active:translate-y-[1px]">
                <div className="rounded-full p-[1px] h-[36px] w-[36px] transition duration-300 group-hover:scale-105">
                  <img className="rounded-full h-full w-full" src="https://pbs.twimg.com/profile_images/1418275523432755201/yer4LloQ_400x400.jpg" alt="Samir Djelal"/>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex items-center space-x-3 font-medium leading-none text-rose-100/90 sm:text-lg">
            
            <NavButton className="text-xs" label="Videos" link="/videos">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-[18px] transform text-rose-100 transition delay-100 duration-500 ease-out group-hover:scale-110">
                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
              </svg>
            </NavButton>
            
            <NavButton className="text-xs" label="Posts" link="/posts">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-[18px] transform text-rose-100 transition delay-100 duration-500 ease-out group-hover:scale-110">
                <path fillRule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clipRule="evenodd"></path>
              </svg>
            </NavButton>
            
            <NavButton externalLink={true} className="text-xs" label="Twitter" link="https://twitter.com/samirdjelal">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-[18px] transform text-rose-100 transition delay-100 duration-500 ease-out group-hover:scale-110" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </NavButton>
            
            <NavButton externalLink={true} className="text-xs" label="Github" link="https://github.com/samirdjelal">
              <svg className="w-[18px] transform text-rose-100 transition delay-100 duration-500 ease-out group-hover:scale-110 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512">
                <path
                  d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"/>
              </svg>
            </NavButton>
            
            <NavButton externalLink={true} className="text-xs" label="Instagram" link="https://instagram.com/samirdjelal">
              <svg className="w-[18px] transform text-rose-100 transition delay-100 duration-500 ease-out group-hover:scale-110 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
              </svg>
            </NavButton>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloatingBanner;