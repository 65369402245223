import React from 'react';
import './App.css';
import HeaderEffect from './components/HeaderEffect';
import Banner from './components/Banner';
import FloatingBanner from './components/FloatingBanner';
import Post from './screens/Post';
import Posts from './screens/Posts';
import Videos from './screens/Videos';
import Home from './screens/Home';
import {Routes, Route} from "react-router-dom";

function App() {
  return (
    <div className="min-h-screen h-full bg-[#0e0c0b] text-white antialiased selection:bg-purple-600/90 selection:text-white">
      <HeaderEffect/>
      <FloatingBanner/>
      <div className="relative z-10 mx-auto w-full px-4 pt-48 sm:max-w-screen-sm">
        <div className="-mt-12 sm:mt-0">
          <Banner/>
          <div className="mt-20 sm:mt-28 pb-20">
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/post/:slug" element={<Post/>}/>
              <Route path="/posts" element={<Posts/>}/>
              <Route path="/videos" element={<Videos/>}/>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
