function Home(props) {
  return (
    <div>
      <div className="leading-8">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, consequatur dolorum ea eaque ex laborum magnam minus necessitatibus nostrum praesentium quam quas qui recusandae, sit voluptatibus. Assumenda nostrum officiis sequi?
      </div>
    </div>
  );
}

export default Home;