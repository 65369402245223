import React from 'react';
import {useParams} from "react-router-dom";

function Post(props) {
  let {slug} = useParams();
  
  return (
    <div>Post: {slug}</div>
  );
}

export default Post;